<template>
  <div class="">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
          placeholder="姓名/手机号" />

        <!--        v-if="roles.code === 'HOTEL_ADMIN'"-->
        <el-select class="select-width" size="medium" v-btn-permission="'008003002001'"
          v-model="queryForm.isolationPointId" placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker v-model="pickerTime" size="medium" type="daterange" value-format="yyyy-MM-dd"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo">重置</el-button>
        <el-button type="primary" v-btn-permission="'008006001006'" size="small" @click="addStaffInfo('add')">新增
        </el-button>
        <el-button type="primary" size="small" @click="exportListData">导出</el-button>
      </div>
    </div>

    <TableModel :is-show-select="true" :loading="loading" :table-data="staffCheckList" table-size="medium">
      <el-table-column label="操作" width="250">
        <template slot-scope="{ row }">
          <el-link type="primary" v-btn-permission="'008003002002'" @click="addStaffInfo('edit', row)">编辑</el-link>
          <el-link type="primary" v-btn-permission="'008003002003'" @click="enterCheckRecord(row)">录入</el-link>
          <el-link type="primary" v-btn-permission="'008003002004'" @click="printWorkerDialog(row)">打印条码</el-link>
          <el-link type="primary" v-btn-permission="'008003002005'" @click="checkRecord(row.id)">历次检测</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="员工姓名" width="130" />
      <el-table-column prop="sex" label="性别" width="130" />
      <el-table-column prop="age" label="年龄" width="130" />
      <el-table-column prop="cardNo" label="身份证号" />
      <el-table-column prop="linkPhone" label="手机号" />
      <el-table-column prop="isolationPointName" label="隔离点" v-btn-permission="'008003002001'" />
      <el-table-column prop="barCode" label="条码号" width="200" />
      <el-table-column prop="lastCollectTime" label="最近一次核酸检测时间" />
      <el-table-column prop="lastLabResult" label="最近一次核酸检测结果">
        <template slot-scope="{ row }">
          <el-tag size="small" type="danger" v-if="row.lastLabResult === '阳性'">阳性</el-tag>
          <el-tag size="small" v-else-if="row.lastLabResult === '阴性'">阴性</el-tag>
          <el-tag size="small" v-else-if="row.lastLabResult === '可疑'">可疑</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content-page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
        :page-size="pageSize" :total="total" :page-sizes="[10, 20, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <!--新增 / 编辑弹窗-->
    <Dialog ref="diglogRef" :dialog-title="dialogTitle" :is-show-dialog="formDialog" label-width="86px"
      @closeDialog="closeDialog" @submitForm="submitFormInfo">
      <el-form-item label="姓名：">
        <el-input v-model="formInfo.name" size="medium" class="form-width" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="身份证号：">
        <el-input v-model="formInfo.cardNo" size="medium" class="form-width" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="formInfo.linkPhone" size="medium" maxlength="11" class="form-width" placeholder="请输入" />
      </el-form-item>
    </Dialog>

    <!--历次检测弹窗-->
    <Dialog dialog-title="历次检测记录" :is-show-dialog="checkRecordDialog" label-width="86px" dialog-width="650px"
      @closeDialog="closeDialog" @submitForm="closeDialog">
      <el-table :data="checkRecordList" :header-cell-style="{ background: '#ffffff', color: '#909399' }" size="medium"
        :max-height="400" style="width: 100%;">
        <el-table-column prop="collectTime" label="检测时间" />
        <el-table-column prop="samplingMode" label="采样方式" />
        <el-table-column prop="labResult" label="检测结果">
          <template slot-scope="{ row }">
            <el-tag size="small" type="danger" v-if="row.labResult === '阳性'">阳性</el-tag>
            <el-tag size="small" v-else-if="row.labResult === '阴性'">阴性</el-tag>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="margin-top: 12px" @size-change="changeSize" @current-change="changeCurrent"
        :current-page="pageData.pageNumber" :page-size="pageData.pageSize" :total="pageData.total"
        :page-sizes="[10, 20, 40, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </Dialog>

    <!--录入弹窗-->
    <EnterCheckRecord ref="EnterCheckRecordRef"></EnterCheckRecord>

    <el-dialog title="核酸检测采样方式" :visible.sync="workerDialog" width="25%">
      <el-form :model="printWorkerForm">
        <el-form-item label="采样方式" :label-width="'120px'">
          <el-select class="select-width" size="medium" v-model="printWorkerForm.samplingMode"
            placeholder="请选择核酸检测采样方式">
            <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
              :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="workerDialog = false">取 消</el-button>
        <el-button type="primary" @click="printWorker()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import TableModel from '@/components/TableModel';
import Dialog from '@/components/Dialog';
import EnterCheckRecord from './EnterCheckRecord';
import {
  workerRegList,
  workerRegInfo,
  saveWorkerReg,
  updateWorkerReg,
  printWorkerCode,
  workerCovidList,
  exportWorkerRegListApi
} from '../../api/HotelManagement/roomManagement';
import { getIdNumberInfo, printBarcode, messageBox, importFile } from '../../utils/utils';
import { isIDCard, isPhone } from '../../utils/validate';
import { mapState } from 'vuex';
import { samplingModeList } from "../../api/QuarantineRegistration/quarantineRegistrationpop";

export default {
  name: "StaffCheck",
  components: { TableModel, Dialog, EnterCheckRecord },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        searchValue: "",
        isolationPointId: ""
      },
      pageData: {
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      formInfo: {
        name: "",
        linkPhone: "",
        cardNo: ""
      },
      loading: false,
      workerDialog: false,
      printWorkerForm: {
        id: '',
        samplingMode: ''
      },
      samplingModeList: [],
      staffCheckList: [],
      pickerTime: [],
      dialogTitle: "",
      formDialog: false,
      checkRecordDialog: false,
      checkRecordList: [],
      isolationPointId: ""
    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList
    })
  },

  created() {
    this.getWorkerRegList();
  },

  methods: {
    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let { searchValue, isolationPointId } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      if (this.pickerTime && this.pickerTime.length == 2) {
        params.startTime = this.pickerTime[0] + " 00:00:00";
        params.endTime = this.pickerTime[1] + " 23:59:59";
      }

      return params;
    },

    getWorkerRegList() {
      this.loading = true;
      let params = this.setParamsInfo();
      workerRegList({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          let { totalRow, list } = data;
          list.forEach(item => {
            if (item.cardNo) {
              let idNumber = getIdNumberInfo(item.cardNo)
              item.age = idNumber.age + " 岁";
              item.sex = idNumber.sex == 0 ? "男" : "女";
            }
          })
          this.staffCheckList = list;
          this.total = totalRow;
        } else {
          this.$message.error(msg);
        }
        this.loading = false;
      })
    },

    // 导出
    exportListData() {
      let params = this.setParamsInfo();
      messageBox(() => {
        exportWorkerRegListApi(params).then(res => {
          importFile(res.data, '工作人员检测数据')
        })
      }, 'warning', '确定需要导出工作人员检测数据吗？')
    },

    queryInfo() {
      this.pageNumber = 1;
      this.getWorkerRegList();
    },

    resetQueryInfo() {
      this.pickerTime = [];
      this.queryForm = {
        searchValue: "",
        isolationPointId: ""
      };
      this.getWorkerRegList();
    },

    closeDialog() {
      if (this.formDialog) this.formDialog = false;
      if (this.checkRecordDialog) this.checkRecordDialog = false;
    },

    // 新增 / 编辑
    addStaffInfo(type, row) {
      this.formInfo = {
        name: "",
        linkPhone: "",
        cardNo: ""
      };
      this.formDialog = true;
      if (type === "add") {
        this.dialogTitle = "新增工作人员信息";
        this.$refs.diglogRef.openDialog(type);
      } else if (type === "edit") {
        this.dialogTitle = "修改工作人员信息";
        this.getWorkerRegInfo(row.id);
        this.$refs.diglogRef.openDialog(type, row.id);
      }
    },

    // 回显数据
    getWorkerRegInfo(id) {
      let params = { id };
      workerRegInfo({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.formInfo = data;
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 表单验证
    verifyFormInfo() {
      let { name, linkPhone, cardNo } = this.formInfo;
      if (!name) {
        this.$message.warning("请输入姓名");
        return false;
      }

      if (!cardNo) {
        this.$message.warning("请输入身份证号");
        return false;
      } else {
        if (!isIDCard(cardNo)) {
          this.$message.warning("请输入正确的身份证号");
          return false;
        }
      }

      if (!linkPhone) {
        this.$message.warning("请输入手机号");
        return false;
      } else {
        if (!isPhone(linkPhone)) {
          this.$message.warning("请输入正确的手机号");
          return false;
        }
      }
      return true;
    },

    // 提交表单
    async submitFormInfo(data) {
      if (!this.verifyFormInfo()) return;

      let params = this.formInfo;
      params.orgCode = this.userdoctor.orgCode;
      let result;
      if (data.type === "add") {
        result = await saveWorkerReg(params);
      } else if (data.type === "edit") {
        params.id = data.id;
        result = await updateWorkerReg(params);
      }
      let { code, msg } = result.data;
      if (code == 200) {
        this.$message.success(msg);
        this.$refs.diglogRef.closeDialog();
        this.getWorkerRegList();
      }
    },

    // 历次检测查看
    checkRecord(id) {
      this.workerRegId = id;
      this.checkRecordDialog = true;
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageData.pageNumber,
        pageSize: this.pageData.pageSize,
        workerRegId: id
      };
      workerCovidList({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          let { totalRow, list } = data;
          this.checkRecordList = list;
          this.pageData.total = totalRow;
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 录入
    enterCheckRecord(row) {
      this.$refs.EnterCheckRecordRef.show(row);
    },
    printWorkerDialog(row) {
      this.printWorkerForm.id = row.id
      this.workerDialog = true
      samplingModeList().then(res => {
        if (res.data.code === "200") {
          this.samplingModeList = res.data.data
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    // 打印条码
    printWorker() {
      printWorkerCode({ params: this.printWorkerForm }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          data.roomNo = '工作人员'
          printBarcode(data)
          this.workerDialog = false
        } else {
          this.$message.error(msg)
        }
      })
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getWorkerRegList();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getWorkerRegList();
    },

    changeSize(val) {
      this.pageData.pageSize = val;
      this.checkRecord(this.workerRegId);
    },

    changeCurrent(val) {
      this.pageData.pageNumber = val;
      this.checkRecord(this.workerRegId);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.form-width {
  width: 280px;
}
</style>

<style scoped>
.el-table>>>td {
  padding: 6px 0 !important;
}

.el-link {
  margin-right: 10px;
}
</style>
